<template>
  <!--login外框-->
  <div class="_login">
      <div class="logo"></div>
    <!--login内框-->
      <div class="login_inner">
          <el-form class="form" ref="form" :model="formLogin">
              <el-form-item>
                  <h2 class="title">城市配管理系统</h2>
              </el-form-item>
              <el-form-item>
                  <el-input v-model="formLogin.username" placeholder="账号"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-input v-model="formLogin.password" placeholder="密码" show-password></el-input>
              </el-form-item>
              <el-form-item prop="vertify_code">
                  <el-input v-model="formLogin.codeNumber" placeholder="验证码" prefix-icon="el-icon-key">
                      <template slot="append">
                        <el-image class="login-code" style="width: 90px" :src="code_url" @click="getVertifyCode" title="看不清？点击切换"></el-image>
                      </template>
                  </el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="login">登陆</el-button>
                  <div v-show="this.errorInfo.isShowError" class="error">{{this.errorInfo.text}}</div>
              </el-form-item>
              <el-form-item>
<!--                  <el-link type="primary" underline @click="reg">点此注册>>>>>></el-link>-->
                  <el-link type="primary" underline @click="forgetPassword" style="float: right">忘记密码</el-link>
              </el-form-item>
          </el-form>
      </div>
    <div class="foter_title">浙公网安备33010802003130号 © <a href="https://beian.miit.gov.cn/" style="color: #ffffff;text-decoration:underline;" target="_blank">浙ICP备15040565号-1</a> © 版权所有 浙江泰易达物流科技有限公司</div>
  </div>
</template>
<style lang="scss">
._login {
  // border:1px solid red;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/login.jpg");
  background-size: cover;
  .logo{
    width: 60px;
    height: 20px;
    position: absolute;
    left: 50px;
    top: 30px;
    background-image: url("../assets/logo.png");
    background-size: cover;
  }
  .login_inner {
    // border:1px solid green;
    width: 460px;
    height: 400px;
    background: #ffffff;
    position: absolute;
    top:150px;
    right: 20px;
    border-radius:5px;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 25px #cac6c6;
    .form {
      // border:1px solid blue;
      width: 300px;
      margin-top: 30px;
      text-align: center;
      .title {
        color: #505458;
      }
    }
    .error {
      color: red;
    }
  }
  .foter_title{
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    bottom: 5px;
    text-align: center;
    color: #ffffff;
  }
}
</style>
<script>
    import { mapActions } from 'vuex'
    import axios from 'axios'
    import qs from 'qs'
    import {Encrypt} from '../util/secret.js'
export default {
    name: "login",
    data() {
        return {
            id:'',
            code_url:'',
            formLogin: {
                username: "",
                password: "",
                codeNumber:''
            },
            errorInfo: {
              text: "登陆失败,请重试",
              isShowError: false //显示错误提示
            },
            menuTreeList:[]
        };
    },
        created () {
            this.getVertifyCode() // 页面初始化从后端加载验证码
        },
        mounted() {
            document.onkeydown = (event) => {
                var router=this.$route.path;
                var e = event || window.event || arguments.callee.caller.arguments[0];
                if (e && e.keyCode == 13&&this.$route.path=='/login') { // enter 键
                    this.login();
                }
            };
        },
        methods: {
            ...mapActions({
                setDeveloperInfo: 'developer/setDeveloperInfo',
            }),
            getVertifyCode(){
                axios.get(`${this.$apiPath}/api/auth/getVertifyCodeImage`).then(res=>{
                    this.code_url = res.data;
                })
            },
            //查询用户所能看到菜单树
            getMenuTree(id){
                axios.get(`${this.$apiPath}/api/pm/login/queryMenus/`+id).then(res=>{
                    this.menuTreeList= res.data;
                    let menu = res.data;
                    //存全部的菜单
                    sessionStorage.setItem('menu', JSON.stringify(menu))
                    this.setDeveloperInfo({
                        data: {
                            authorManagement: false,
                            cityDistribution: false,
                            controlTime: false,
                        }
                    })
                })
                axios.get(`${this.$apiPath}/api/pm/login/queryButtons/`+id).then(res=>{
                    sessionStorage.setItem('userButtons', JSON.stringify(res.data))
                })
            },
            login() {
                if(this.formLogin.username == ''){
                    this.$notify.error({
                        title: '通知',
                        message: '请输入您的用户名'
                    });
                }else if(this.formLogin.password == ''){
                    this.$notify.error({
                        title: '通知',
                        message: '请输入您的密码'
                    });
                }else{
                    //提交登录
                    let param = {
                        username:this.formLogin.username,
                        password:Encrypt(this.formLogin.password),
                        codeNumber:this.formLogin.codeNumber
                    };
                    axios.post(`${this.$apiPath}/api/auth/authorize`,qs.stringify(param))
                        .then((response) => {
                            if(response.data.success == true){
                                this.ceshi();
                              this.roleEnum = sessionStorage.getItem('roleEnum'),
                                  // this.$route.query.roleEnum,
                                  console.log(this.roleEnum);
                                this.$router.replace({ path: "/index" });
                            }else {
                                this.$alert(response.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                                this.getVertifyCode();
                            }
                        }).catch((err)=>{
                    })
                }
            },
            reg(){
                let routeUrl = this.$router.resolve({
                    path: "/reg"
                });
                window.open(routeUrl.href, '_blank');
            },
            forgetPassword(){
                let routeUrl = this.$router.resolve({
                    path: "/forgetPassword"
                });
                window.open(routeUrl.href, '_blank');
                //this.$router.replace({ path: "/forgetPassword" });
            },
            ceshi(){
                axios.get(`${this.$apiPath}/api/auth/verify`).then(res=>{
                    this.getMenuTree(res.data.id);
                    let userPermissions = {};
                    userPermissions.id  = res.data.id;
                    userPermissions.chintBusiness = res.data.chintBusiness;
                    userPermissions.outGoing = res.data.outGoing;
                    userPermissions.regionLevel = res.data.regionLevel;
                    sessionStorage.setItem('userPermissions', JSON.stringify(userPermissions))
                })
            }
        }
};
</script>
